<template>
  <div>
    <navbar />
    <bread-creamb title="About Us" />
    <!-- about us page start here  -->
    <section
      id="hwa-about-us"
      class="site-about-view"
    >
      <div class="container">
        <div class="about-main-view">
          <div
            class="qoute-image"
            :style="{
              'background-image': `url(${require('@/assets/images/slider/about1.jpg')})`,
            }"
          />
          <div class="about-text-view">
            <h1 class="page-heading-1">
              About
            </h1>
            <h1 class="heading-text">
              {{ about.title }}
            </h1>
            <p>{{ about.description }}</p>
            <div class="site-button-group mt-3">
              <div
                :class="`site-btn ${active_tab === 1 ? 'active' : ''}`"
                @click="ActivateTab(1)"
              >
                Our Mission
              </div>
              <div
                :class="`site-btn ${active_tab === 2 ? 'active' : ''}`"
                @click="ActivateTab(2)"
              >
                Our Vision
              </div>
              <div
                :class="`site-btn ${active_tab === 3 ? 'active' : ''}`"
                @click="ActivateTab(3)"
              >
                Our Goals
              </div>
            </div>

            <p v-if="active_tab === 1">
              {{ about.mission }}
            </p>
            <p v-if="active_tab === 2">
              {{ about.vision }}
            </p>
            <p v-if="active_tab === 3">
              {{ about.goal }}
            </p>

          </div>
        </div>
      </div>
    </section>
    <!-- about us page end here -->
    <!-- <testimonial /> -->
    <!-- <blog /> -->
    <subscribe />
    <app-footer />
  </div>
</template>

<script>
import AppFooter from './site-content/AppFooter.vue'
import Blog from './site-content/Blog.vue'
import Navbar from './site-content/Navbar.vue'
import Subscribe from './site-content/Subscribe.vue'
import Testimonial from './site-content/Testimonial.vue'
import BreadCreamb from './site-pages/BreadCreamb.vue'

export default {
  components: {
    Navbar, BreadCreamb, AppFooter, Testimonial, Blog, Subscribe,
  },
  data() {
    return {
      active_tab: 1,
    }
  },
  computed: {
    about() {
      return this.$store.getters['auth/aboutContent']
    },
  },
  methods: {
    ActivateTab(data) {
      this.active_tab = data
    },
  },
}
</script>

<style>

</style>
